@import '~animate.css/animate.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto-Light';
  src: url('../fonts/Roboto/Roboto-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../fonts/Roboto/Roboto-Medium.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/Roboto/Roboto-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto/Roboto-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Roboto-Italic';
  src: url('../fonts/Roboto/Roboto-Italic.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Helvetica-Medium';
  src: url('../fonts/Helveltica/helvetica_medium.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../fonts/Montserrat/Montserrat-SemiBold.ttf');
  font-display: swap;
}

body {
  font-family: 'Roboto-Regular', sans-serif;
}
